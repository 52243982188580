import { HouseholdCover, HouseholdInsurance } from '../entities/household';
import { Injectable, signal } from '@angular/core';
import { JourneyCover, JourneyCoverWithItsJourneyInsurance, JourneyInsurance } from '../entities/journey-insurance';
import { MasterDataItem, MasterDataService } from './master-data.service';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { getCurrency, isNullOrUndefined, mapJourneyIdToDtoId, one } from '../shared/util';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { InsuranceOwnerDropdownOption } from '../enums/insurance-owner-dropdown-option';
import { InsuranceStructures } from '../enums/insurance-structures';
import { JourneyClientService } from './journey-client.service';
import { JourneyService } from './journey.service';
import { NameValue } from '../entities/name-value';
import { OwnerType } from '../entities/owner-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root'
})
export class JourneyInsuranceService {

  constructor(
    private readonly http: HttpClient,
    private readonly journeyService: JourneyService,
    private readonly householdService: HouseholdService,
    private readonly journeyClientService: JourneyClientService
  ) { }

  private readonly apiPath = "/api/journeyInsurances";
  private _dataInitialised = false;

  get dataInitialised() {
    return this._dataInitialised;
  }

  items = signal<JourneyInsurance[]>([]);

  async refresh() {
    const household = this.householdService.household;
    if (!household) {
      throw new Error("The household object has not been initialised.");
    }

    const items: JourneyInsurance[] = [];
    const now = new Date();

    items.push(...household.insurances.map(x => this.mapHouseholdToJourney(x, "joint", now)));
    items.push(...household.client.insurances.map(x => this.mapHouseholdToJourney(x, "primary", now)));
    items.push(...household.spouse?.insurances.map(x => this.mapHouseholdToJourney(x, "spouse", now)) ?? []);

    await this.saveToApi(items);
    this.items.set(items);
    this._dataInitialised = true;
  }

  private mapHouseholdToJourney(dto: HouseholdInsurance, owner: OwnerType, now: Date): JourneyInsurance {
    if (!dto.id) {
      throw new Error("A HouseholdInsurance object cannot have null value for its id column.");
    }

    const journeyCovers: JourneyCover[] = [];
    for (const cover of dto.covers) {
      if (!cover.id) {
        throw new Error("A HouseholdCover object cannot have null value for its id column.");
      }

      journeyCovers.push({ value: cover.value, coverID: cover.id.toString(), insuranceTypeId: cover.insuranceTypeId });
    }

    return {
      policyNumber: dto.policyNumber,
      supplierId: dto.supplierId,
      covers: journeyCovers,
      structureTypeId: dto.structureTypeId,
      insuredIndividualIsPrimaryIndividual: dto.insuredIndividualIsPrimaryIndividual,

      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
      owner,
      insuranceID: dto.id.toString(),
      created: now,
      lastModified: now,
      isDeleted: false
    };
  }

  async save(item: JourneyInsurance) {
    await this.saveToApi([item]);
    this.items.update(current => [...current.filter(x => x.insuranceID !== item.insuranceID), item]);
  }

  async removeCoverFromInsurance(item: JourneyInsurance, cover: JourneyCover) {
    item.covers = item.covers.filter(x => x.coverID !== cover.coverID);
    await this.saveToApi([item]);
    this.items.update(current => [...current.filter(x => x.insuranceID !== item.insuranceID), item]);
  }

  async deleteInsurance(item: JourneyInsurance) {
    item.isDeleted = true;
    await this.saveToApi([item]);
    this.items.update(current => [...current.filter(x => x.insuranceID !== item.insuranceID)]);
  }

  refreshFromApi() {
    this.ensureDataInitialised();
    return this.getFromApi()
      .pipe(tap((items) => { this.items.set(items) }))
  }

  ensureDataInitialised() {
    if (!this.dataInitialised) {
      throw new Error("The data has not been initialised.");
    }
  }

  private getFromApi(previousJourneyInstanceID?: string): Observable<JourneyInsurance[]> {
    const journeyId = this.journeyService.getNonNullableJourney().journeyID;
    const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
    return this.http.get<JourneyInsurance[]>(`${this.apiPath}/${journeyId}/${journeyInstanceId}/latest`);
  }

  // This method cannot be static because it must be callable via DI. 
  // eslint-disable-next-line class-methods-use-this
  getInsuranceOwner(insuranceOwner: InsuranceOwnerDropdownOption): OwnerType {
    if ([InsuranceOwnerDropdownOption.primary, InsuranceOwnerDropdownOption.primarySuper].includes(insuranceOwner)) {
      return "primary"
    } else if ([InsuranceOwnerDropdownOption.spouse, InsuranceOwnerDropdownOption.spouseSuper].includes(insuranceOwner)) {
      return "spouse";
    } else if ([InsuranceOwnerDropdownOption.joint].includes(insuranceOwner)) {
      return "joint";
    }

    throw new Error(`insuranceOwner has an invalid value: ${insuranceOwner}`)
  }

  // This method cannot be static because it must be callable via DI. 
  // eslint-disable-next-line class-methods-use-this
  getInsuranceStructureTypeId(insuranceOwner: InsuranceOwnerDropdownOption) {
    if ([InsuranceOwnerDropdownOption.primarySuper, InsuranceOwnerDropdownOption.spouseSuper].includes(insuranceOwner)) {
      return InsuranceStructures.Super;
    } else if ([InsuranceOwnerDropdownOption.spouse, InsuranceOwnerDropdownOption.primary, InsuranceOwnerDropdownOption.joint].includes(insuranceOwner)) {
      return InsuranceStructures.Ordinary;
    }

    throw new Error(`insuranceOwner has an invalid value: ${insuranceOwner}`)
  }

  // This method cannot be static because it must be callable via DI. 
  // eslint-disable-next-line class-methods-use-this
  getOwnerDropdownValue(owner: OwnerType, insuranceTypeId: number) {
    if (owner === 'primary') {
      return insuranceTypeId === InsuranceStructures.Super.valueOf() ? InsuranceOwnerDropdownOption.primarySuper : InsuranceOwnerDropdownOption.primary;
    } else if (owner === "spouse") {
      return insuranceTypeId === InsuranceStructures.Super.valueOf() ? InsuranceOwnerDropdownOption.spouseSuper : InsuranceOwnerDropdownOption.spouse;
    }

    return InsuranceOwnerDropdownOption.joint;
  }

  // This method cannot be static because it must be callable via DI. 
  // eslint-disable-next-line class-methods-use-this
  getInsuranceOwnerDropdownData(getSpouseEvenIfThereIsNone = false): MasterDataItem[] {
    const primaryName = this.journeyClientService.getOwnerDropdownName("primary");
    const data: MasterDataItem[] = [
      { iD: InsuranceOwnerDropdownOption.primary, name: primaryName },
      { iD: InsuranceOwnerDropdownOption.primarySuper, name: `${primaryName}'s Super` },
    ];
    if (getSpouseEvenIfThereIsNone || this.journeyClientService.spouseJourneyClient()) {
      const spouseName = this.journeyClientService.getOwnerDropdownName("spouse");
      data.push(...[
        { iD: InsuranceOwnerDropdownOption.spouse, name: spouseName },
        { iD: InsuranceOwnerDropdownOption.spouseSuper, name: `${spouseName}'s Super` },
      ])
    }

    data.push({ iD: InsuranceOwnerDropdownOption.joint, name: this.journeyClientService.getOwnerDropdownName("joint") })
    return data;
  }

  getOwnerDropdownName(item: JourneyInsurance) {
    const dropdownData = this.getInsuranceOwnerDropdownData(true);
    const dropdownValue = this.getOwnerDropdownValue(item.owner, item.structureTypeId);
    return MasterDataService.getNameForId(dropdownValue, dropdownData);
  }


  async mapJourneyToHousehold(): Promise<Record<OwnerType, HouseholdInsurance[]>> {
    await firstValueFrom(this.refreshFromApi());
    this.ensureDataInitialised();
    return {
      primary: JourneyInsuranceService.mapJourneyToHouseholdForOwner(this.items(), "primary"),
      spouse: JourneyInsuranceService.mapJourneyToHouseholdForOwner(this.items(), "spouse"),
      joint: JourneyInsuranceService.mapJourneyToHouseholdForOwner(this.items(), "joint")
    }
  }

  getPdfSections(): PdfSection[] {
    const pdfSections: PdfSection[] = [];

    const items: JourneyCoverWithItsJourneyInsurance[] = this.items().flatMap(x => x.covers.map(y => ({ cover: y, insurance: x })));

    for (const coverType of MasterDataService.getInsuranceCoverTypes()) {
      const covers = items.filter(x => x.cover.insuranceTypeId === coverType.iD);

      if (!covers[0]) continue;

      pdfSections.push(...this.getCoverPDFSections(covers, coverType.name));
    }

    if (pdfSections[0]) pdfSections.push(PdfService.getPDFBreakLineSection());

    return pdfSections;
  }


  async loadJourneyInsurances(previousJourneyInstanceID: string): Promise<void> {
    const items = await firstValueFrom(this.getFromApi(previousJourneyInstanceID));

    const now = new Date();
    for (const item of items) {
      item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
      item.created = now;
      item.lastModified = now;
    }

    await this.saveToApi(items);
    this.items.set(items);
    this._dataInitialised = true;
  }

  private getCoverPDFSections(items: JourneyCoverWithItsJourneyInsurance[], name: string): PdfSection[] {
    const pdfSections: PdfSection[] = [];

    for (const [index, item] of items.entries()) {
      const pdfSection: PdfSection = {
        breakLine: false,
        pdfSectionType: PdfSectionTypes.Table,
        title: `${name} ${index + one}`,
        content: {
          tableHeaders: [
            {
              name: "Field",
              width: "30%"
            },
            {
              name: this.journeyClientService.getOwnerDropdownName(item.insurance.owner),
              width: "60%"
            }
          ],
          tableRows: []
        }
      };

      const nameValues = this.getJourneyInsuranceFields(item);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

      pdfSections.push(pdfSection);
    }

    return pdfSections;
  }

  private getJourneyInsuranceFields(journeyCoverWithItsJourneyInsurance: JourneyCoverWithItsJourneyInsurance): NameValue[] {
    return [
      {
        name: "Level of Cover",
        value: getCurrency(journeyCoverWithItsJourneyInsurance.cover.value),
      },
      {
        name: "Supplier",
        value: MasterDataService.getNameForIdWithDefault(journeyCoverWithItsJourneyInsurance.insurance.supplierId, MasterDataService.getInsuranceProviderTypes())
      },
      {
        name: "Policy Number",
        value: journeyCoverWithItsJourneyInsurance.insurance.policyNumber
      },
      {
        name: "Insured individual",
        value: journeyCoverWithItsJourneyInsurance.insurance.insuredIndividualIsPrimaryIndividual
          ? this.journeyClientService.getClientName("primary")
          : this.journeyClientService.getClientName("spouse")
      },
      {
        name: "Owner",
        value: this.getOwnerDropdownName(journeyCoverWithItsJourneyInsurance.insurance)
      }
    ];
  }

  private static mapJourneyToHouseholdForOwner(items: JourneyInsurance[], owner: OwnerType): HouseholdInsurance[] {
    return items
      .filter(x => x.owner === owner)
      .map(x => ({
        id: mapJourneyIdToDtoId(x.insuranceID),
        policyNumber: x.policyNumber,
        supplierId: x.supplierId,
        structureTypeId: x.structureTypeId,
        covers: JourneyInsuranceService.mapJourneyCoversToDtoCovers(x.covers),
        insuredIndividualIsPrimaryIndividual: x.insuredIndividualIsPrimaryIndividual
      }))
      .filter(x => JourneyInsuranceService.removeIfNewInsuranceAndNoCovers(x));
  }

  private static removeIfNewInsuranceAndNoCovers(insurance: HouseholdInsurance) {
    // There is no need to save such insurances to AOS.
    // eslint-disable-next-line no-magic-numbers
    if (isNullOrUndefined(insurance.id) && insurance.covers.length === 0) {
      return false;
    }

    return true;
  }

  private static mapJourneyCoversToDtoCovers(items: JourneyCover[]): HouseholdCover[] {
    return items
      .map(x => ({
        value: x.value,
        insuranceTypeId: x.insuranceTypeId,
        id: mapJourneyIdToDtoId(x.coverID)
      }));
  }

  private async saveToApi(items: JourneyInsurance[]): Promise<void> {
    await firstValueFrom(this.http.post(`${this.apiPath}`, items));
  }
}
